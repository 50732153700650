/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";

import { Artwork, BackLink, Layout } from "components";
import { getArtistName, getArtistPath } from "utils";
import { Fragment } from "react";

function ArtworkPage({ data: { artwork } }) {
  const {
    _id,
    name,
    medium = {},
    image,
    work_title,
    artist,
    content,
    work_date,
  } = artwork;
  const artworkName = work_title || name;
  const artistName = getArtistName(artist);

  return (
    <Layout
      pageTitle={[artworkName, artistName, "Artists"]}
      artworks={[artwork]}
    >
      {({ openContactForm }) => (
        <Fragment>
          {artist.create_web_page && (
            <div sx={{ mb: "s" }}>
              <BackLink to={getArtistPath(artist)} sx={{ mb: "s" }}>
                {artistName}
              </BackLink>
            </div>
          )}

          <Artwork
            id={_id}
            name={artworkName}
            medium={medium?.medium}
            gatsbyImageData={image?.asset?.gatsbyImageData}
            content={content}
            workDate={work_date}
            artist={artist}
            openContactForm={openContactForm}
          />
        </Fragment>
      )}
    </Layout>
  );
}

export const query = graphql`
  query Artwork($id: String) {
    artwork: sanityArtwork(_id: { eq: $id }) {
      _id
      name
      medium {
        medium
      }
      image: work_image {
        asset {
          gatsbyImageData(height: 1000)
        }
      }
      work_title
      artist {
        create_web_page
        first_name
        last_name
        yearOfDeath: date_of_death(formatString: "yyyy")
        yearOfBirth: date_of_birth(formatString: "yyyy")
      }
      work_date
      content: _rawDetails(resolveReferences: { maxDepth: 10 })
    }
  }
`;

export default ArtworkPage;
